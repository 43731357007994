import React, { useContext, useState } from "react"
import styled from "styled-components"
import { GatsbyContext } from "../context/gatsby"

import Searchbar from "./Searchbar"
import { AiOutlineQuestionCircle, AiOutlineBell, AiOutlineSetting, AiOutlineUser } from "react-icons/ai"
import { navigate } from "gatsby-link"
import MenuItem from "@mui/material/MenuItem"
import { AppBar, Toolbar, Button, Menu, Box, Typography, ListItem, IconButton, Badge } from "@mui/material"
import Divider from "@mui/material/Divider"
import { useDispatch, useSelector } from "react-redux"
import { useKeycloak } from "@react-keycloak/web"

const Navbar = () => {
    const { keycloak, initialized } = useKeycloak()
    const { mainLinks } = useContext(GatsbyContext)
    const storeItems = useSelector(store => {
        return {
            req: store.req.reqItems,
            cart: store.cart.cartItems,
            order: store.order.orderItems,
            invoicesRegistry: store.invoicesRegistry.invoiceItems,
        }
    })

    const accountMenuId = "primary-account-menu"
    const [anchorAccountMenuEl, setAnchorAccountMenuEl] = useState(null)
    const handleAccountMenuClose = () => {
        setAnchorAccountMenuEl(null)
    }
    const handleAccountMenuOpen = event => {
        setAnchorAccountMenuEl(event.currentTarget)
    }

    const handleAccountMenuLogOut = e => {
        e.preventDefault()
        keycloak.logout()
    }

    const handleAccountMenuProfile = e => {
        e.preventDefault()
        navigate("/profile")
    }

    const handleAccountMenuSettings = e => {
        e.preventDefault()
        navigate("/settings")
    }

    const renderAccountMenu = (
        <Menu
            anchorEl={anchorAccountMenuEl}
            transitionDuration={0}
            id={accountMenuId}
            keepMounted
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            elevation={0}
            open={Boolean(anchorAccountMenuEl)}
            onClose={handleAccountMenuClose}
            PaperProps={{
                sx: {
                    overflow: "visible",
                    mt: 1.5,
                    border: 1,
                    borderColor: "grey.500",
                    width: "180px",
                    "&:before": {
                        backgroundColor: "background.paper",
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: -1,
                        right: 16,
                        width: 12,
                        height: 12,
                        borderLeft: 1,
                        borderTop: 1,
                        borderColor: "grey.500",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                    },
                },
            }}
            MenuListProps={{ onMouseLeave: handleAccountMenuClose }}
        >
            <Box
                sx={{
                    display: "block",
                    paddingTop: "6px",
                    paddingBottom: "6px",
                    paddingLeft: "16px",
                    paddingRight: "16px",
                }}
            >
                <Typography>Вы вошли как</Typography>
                <Typography sx={{ fontWeight: "medium" }}>{keycloak.tokenParsed.preferred_username}</Typography>
                {sessionStorage.getItem("lk_username") !== null && (
                    <>
                        <Typography sx={{ fontWeight: "small" }}>Управление пользователем</Typography>
                        <Typography sx={{ fontWeight: "medium" }}>{sessionStorage.getItem("lk_username")}</Typography>
                    </>
                )}
            </Box>
            <Divider />
            <MenuItem onClick={handleAccountMenuProfile}>Профиль</MenuItem>
            <MenuItem onClick={handleAccountMenuSettings}>Настройки</MenuItem>
            <Divider />
            <MenuItem onClick={handleAccountMenuLogOut}>Выход</MenuItem>
        </Menu>
    )

    return (
        <AppBar
            sx={{
                position: "static",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "4rem",
                minHeight: "4rem",
                paddingRight: "0 !important",
            }}
            elevation={0}
        >
            <Toolbar
                sx={{
                    width: "90vw",
                    maxWidth: "1200px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
                disableGutters={true}
            >
                <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                    <Typography
                        variant="h4"
                        noWrap
                        component="div"
                        onClick={() => {
                            navigate("/")
                        }}
                        sx={{ "&:hover": { cursor: "pointer" } }}
                    >
                        5025.PRO
                    </Typography>

                    <Searchbar />
                    <Box>
                        {mainLinks.map(mainLink => {
                            return (
                                <Button
                                    key={mainLink.page}
                                    onClick={() => {
                                        navigate(mainLink.route)
                                    }}
                                    sx={{ marginLeft: 2, color: "white" }}
                                >
                                    {mainLink.store ? (
                                        <Badge badgeContent={storeItems[mainLink.store].length} color="error">
                                            {mainLink.page}
                                        </Badge>
                                    ) : (
                                        <>{mainLink.page}</>
                                    )}
                                </Button>
                            )
                        })}
                    </Box>
                </Box>
                <Box>
                    <IconButton aria-label="help icon" color="inherit" onClick={() => navigate("/help")}>
                        <AiOutlineQuestionCircle />
                    </IconButton>

                    <IconButton aria-label="bell icon" color="inherit" onClick={() => navigate("/notifications")}>
                        <Badge badgeContent={0} color="error">
                            <AiOutlineBell />
                        </Badge>
                    </IconButton>
                    <IconButton
                        aria-label="account icon"
                        color="inherit"
                        onClick={handleAccountMenuOpen}
                        onMouseOver={handleAccountMenuOpen}
                    >
                        <AiOutlineUser />
                    </IconButton>
                </Box>
            </Toolbar>

            {renderAccountMenu}
        </AppBar>
    )
}

const Wrapper = styled.main`
    .navbar {
        height: 4rem;
        background: var(--primary-500);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .nav-center {
        width: 90vw;
        max-width: var(--max-width);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .nav-header {
        padding: 0;
        font-size: 200%;
        width: 200px;
        font-weight: 500;
    }
    a {
        color: var(--grey-300);
    }

    .nav-links {
    }

    .nav-icon-links {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .nav-icon-link {
        margin-left: 1rem;
        font-size: 150%;
        color: var(--white);
        cursor: pointer;
        transition: var(--transition);
    }
    .nav-icon-link:hover {
        color: var(--primary-700);
    }

    @media screen and (min-width: 992px) {
        .navbar {
        }
        .nav-center {
        }
        .nav-header {
        }

        .nav-links {
        }
        .nav-icon-links {
        }
        .nav-icon-link {
        }
        .nav-icon-link:hover {
        }
    }
`

export default Navbar
