const keycloak = require("./src/keycloak/keycloak").default
const React = require("react")
const { ReactKeycloakProvider } = require("@react-keycloak/web")
const Loading = require("./src/components/Loading").default
const { ApolloProvider } = require("@apollo/client")
const client = require("./src/apollo/client").default
const Layout = require("./src/components/Layout").default
const { GatsbyProvider } = require("./src/context/gatsby")
const { Provider } = require("react-redux")
const { store } = require("./src/context/reduxStore")

const Cookies = require("js-cookie")

exports.wrapPageElement = ({ element, props }) => {
    return <Layout {...props}>{element}</Layout>
}

const onKeycloakTokens = ({ token, refreshToken, idToken }) => {
    // localStorage.setItem("kc_token", token)
    // localStorage.setItem("kc_refreshToken", refreshToken)
    // localStorage.setItem("kc_idToken", idToken)
}

const tokenOptions = () => {
    //localStorage.setItem("lk_access_token_read", localStorage.getItem("lk_access_token"))

    // const kc_token = document.cookie.replace(/(?:(?:^|.*;\s*)lk_access_token\s*\=\s*([^;]*).*$)|^.*$/, "$1") //Cookies.get("lk_access_token") //localStorage.getItem("kc_token")
    // const kc_refreshToken = document.cookie.replace(/(?:(?:^|.*;\s*)lk_refresh_token\s*\=\s*([^;]*).*$)|^.*$/, "$1") //Cookies.get("lk_refresh_token") //localStorage.getItem("kc_refreshToken")
    // const kc_idToken = document.cookie.replace(/(?:(?:^|.*;\s*)lk_access_token\s*\=\s*([^;]*).*$)|^.*$/, "$1") //Cookies.get("lk_access_token") //localStorage.getItem("kc_idToken")
    // if (!kc_token || kc_token === "undefined") {
    //     localStorage.setItem("kc_token", kc_token)
    //     localStorage.setItem("kc_refreshToken", kc_refreshToken)
    //     localStorage.setItem("kc_idToken", kc_idToken)
    // }

    const token = localStorage.getItem("lk_access_token")
    const refreshToken = localStorage.getItem("lk_refresh_token")
    const idToken = localStorage.getItem("lk_access_token")

    return { token, refreshToken, idToken, timeSkew: 0 }

    // return { kc_token, kc_refreshToken, kc_idToken, timeSkew: 0 }

    // if (!token || token === "undefined") {
    //     return {}
    // }
    // return { token, refreshToken, idToken, timeSkew: 0 }
}

const Impersonate = ({ element }) => {
    React.useEffect(() => {
        sessionStorage.setItem("fullReload", true)

        const handleImpersonate = e => {
            if (e.origin !== process.env.GATSBY_LKM_URL) {
                return
            }

            const data = JSON.parse(e.data)
            if (typeof data.sub !== "undefined") {
                sessionStorage.setItem("lk_sub", data.sub)
                sessionStorage.setItem("lk_username", data.username)
            }
        }
        window.addEventListener("message", handleImpersonate)

        return () => {
            window.removeEventListener("message", handleImpersonate)
        }
    }, [])
    return (
        <>
            <ReactKeycloakProvider
                authClient={keycloak}
                initOptions={{
                    onLoad: "login-required",
                    enableLogging: true,
                    pkceMethod: "S256",
                }}
                LoadingComponent={<Loading />}
                isLoadingCheck={keycloak => !keycloak.authenticated}
            >
                <GatsbyProvider>
                    <Provider store={store}>
                        <ApolloProvider client={client}>{element}</ApolloProvider>
                    </Provider>
                </GatsbyProvider>
            </ReactKeycloakProvider>
        </>
    )
}

exports.wrapRootElement = ({ element }) => {
    return <Impersonate element={element} />
}
